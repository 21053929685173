import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';

import { Order, PageOrderFlow, Transaction } from '../../page/types';
// import {
//   createOrderSuccess,
//   createOrderFailure,
// } from '../actions';
import { fetchOrderTransactions } from '../../page/initiators';
import { Cart, SubmittedOrder } from '../types';
import { User } from '../../user/types';
import { setOrdersSubmitting, updateSubmittedOrders } from '../actions';
import { asyncRemoveItemFromCart } from './asyncRemoveItemFromCart';

// Thunk action to create orders
export const asyncCartOrdersCreate = () => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    try {
      // Get the loginToken from the Redux state
      const { auth } = getState();
      const cartState = getState().cart;
      const loginToken = auth.loginToken;
      const { user } = getState().user;
      const { orderFlows, cart } = cartState;
      let submittedOrders: SubmittedOrder[] = [];
      dispatch(updateSubmittedOrders(submittedOrders));
      dispatch(setOrdersSubmitting(true));
      // Iterate over each order flow
      for (const orderFlow of orderFlows) {
        // Create order for the current order flow
        if (loginToken && cart && user) {
          const submittedOrder = await createOrdersFromCart(orderFlow, loginToken, user);
          if (submittedOrder) {
            submittedOrders.push(submittedOrder);
            dispatch(updateSubmittedOrders(submittedOrders));
          }
        }
      }
      dispatch(updateSubmittedOrders(submittedOrders));
      for (const cartItem of cart?.items || []) {
        // Create remove items from cart
        if (cartItem.selected) {
          await dispatch(asyncRemoveItemFromCart(cartItem._id))
        }
      }
      dispatch(setOrdersSubmitting(false));
    } catch (error) {
      console.error('Error creating order:', error);
      dispatch(setOrdersSubmitting(false));
    }
  };
};

const createOrdersFromCart = async (orderFlow: PageOrderFlow, loginToken: string, user: User) => {
    // Construct the headers with X-Auth-Token
    const headers = {
      'X-Auth-Token': loginToken,
    };

    // Make adjustments to the structure of the data
    const {
      creatorId,
      userId,
      distance,
      branchCode,
      channel,
      sourceId,
      sourceType,
      destinationId,
      destinationType,
      orderExpenses,
      orderRevenue,
      tip,
      deliveryFee,
      deliveryType,
      originBranchCode,
      deliveryCommission,
      valueFee,
      discountAmount,
      discountDescription,
      pagesTransactionFee,
      tax,
      currency,
      targetDeliveryTime,
      pickupInstruction,
      deliveryInstruction,
      data,
      source
    } = orderFlow;

    const business = source;

    var newOrderData: any = {
      ...data,
      searchTags: []
    };

    var newOrder: any = {
      creatorId,
      userId,
      sourceType,
      destinationType,
      sourceId,
      destinationId,
      distance,
      branchCode,
      channel,
      orderExpenses,
      orderRevenue,
      deliveryFee,
      deliveryType,
      originBranchCode,
      deliveryCommission,
      valueFee,
      tip,
      discountAmount,
      discountDescription,
      pagesTransactionFee,
      tax,
      currency,
      pickupInstruction,
      deliveryInstruction,
      status: 'created',
      targetDeliveryTime
    }

    var nameTags: string[] = [];
    var numberTags: string[] = [];
    var emailTags: string[] = [];

    newOrder.sourceType = 'businessLocation';
    newOrder.destinationType = 'userLocation';
    newOrder.userId = user?._id;
    nameTags = [user?.firstName + ' ' + user?.lastName, business.name];
    numberTags = [user?.phoneNumber + ''];
    if (user?.email) {
      emailTags = [user?.email];
    }
    newOrderData.searchTags = [
      ...newOrderData.searchTags,
      ...nameTags,
      ...numberTags,
      ...emailTags
    ];
    newOrder.data = newOrderData;

    const orderResponse = await backend.post('/commerce-service/api/v1/orders', newOrder, { headers });
    let order: Order | null = null;
    let transactions: Transaction[] = []
    if (orderResponse.status === 201) {
      order = orderResponse.data;
    }
    if (order) {
      // const transactionsResponse = await backend.get(`/commerce-service/api/v1/orders/${order._id}/transactions?describe=1`, { headers });
      // if (transactionsResponse.status === 200) {
      //   transactions = transactionsResponse.data;
      //   const SubmittedOrder: SubmittedOrder = {
      //     confirmation: order, transactions: []
      //   }
      //   return SubmittedOrder;
      // }
        const SubmittedOrder: SubmittedOrder = {
          confirmation: order, transactions: []
        }
        return SubmittedOrder;
    }
}