import { Dispatch } from "redux"
import { ReduxState } from "../../state"
import { CartItem } from "../types";
import { Location } from "../../location/types";
import backend from "../../../apis/backend";
import { OrderItem, PageOrderFlow } from "../../page/types";
import { User } from "../../user/types";
import { setOrdersCalculating, updateOrdersFlow } from "../actions";

export const asyncCalculateCartFees = () => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const { cart, orderFlows } = getState().cart;
    const { loginToken } = getState().auth;
    const { user } = getState().user;
    const { selectedLocation } = getState().location;
    // Group cart items by businessLocationId
    const groupedCartItems: Record<string, CartItem[]> = (cart?.items || []).reduce((groups: any, item) => {
      const { businessLocationId } = item;
      if (!groups[businessLocationId]) {
        groups[businessLocationId] = [];
      }
      groups[businessLocationId].push(item);
      return groups;
    }, {});
    dispatch(setOrdersCalculating(true));
    // Use Promise.all to wait for all the asynchronous calls to complete
    const promises = Object.entries(groupedCartItems).map(async ([businessLocationId, items]) => {
      return await calculateGroupFees(businessLocationId, items, loginToken, selectedLocation, user);
    });

    // Wait for all promises to resolve
    let cartFlows = await Promise.all(promises);
    let updatedOrderFlows = cartFlows.filter(flow => flow !== undefined) as PageOrderFlow[];
    dispatch(setOrdersCalculating(false));
    dispatch(updateOrdersFlow(updatedOrderFlows))
  }
}

const calculateGroupFees = async (defaultBusinessLocationId: string, cartItems: CartItem[], loginToken: string | null, selectedLocation: Location | null, user: User | null) => {
  if (cartItems.length > 0 && cartItems[0].reference?.associatedBusinesses && cartItems[0].reference?.associatedBusinesses.length > 0 && selectedLocation && user) {
    let leadTimeHoursList: number[] = [];
    let deliveryDiscountList: { deliveryDiscount: number, deliveryDiscountMode: string | null}[] = [];
    let discountList: { discount: number | undefined, discountMode: string | null, discountAmount: number, discountName: string | undefined, _id: string}[] = [];
    const business = cartItems[0].reference?.associatedBusinesses[0];
    const businessId = business._id;
    const { currency } = cartItems[0];
    let orderItems: OrderItem[] = [];
    cartItems.map((cartItem: CartItem) => {
      if (cartItem.reference?.pageData && cartItem.selected) {
        const { 
          leadTimeHours, 
          deliveryDiscount, deliveryDiscountMode, 
          discount, discountMode, discountName 
        } = cartItem.reference.pageData;
        const { quantity } = cartItem
        const { price } = cartItem;
        leadTimeHoursList.push(leadTimeHours)
        deliveryDiscountList.push({ deliveryDiscount, deliveryDiscountMode})
        if (cartItem.selected) orderItems.push({
          _id: cartItem.reference._id,
          price: cartItem.reference.price,
          name: cartItem.reference.name,
          status: cartItem.reference.status,
          currency: cartItem.currency,
          quantity: cartItem.quantity,
          data: {
            details: '',
            addOns: []
          }
        })
        let discountAmount = 0;
        if (discount && discountMode === 'amount') discountAmount = -1 * discount;
        if (discount && discountMode === 'percent') discountAmount = -1 * price * quantity * discount / 100;
        if (discountAmount < 0) discountList.push({ discount, discountMode, discountAmount, discountName, _id: `item-discount-${cartItem.itemId}`})
      }
    })
    const headers = {
      'X-Auth-Token': loginToken,
    };  
    const destinationLatitude = selectedLocation.latitude;
    const destinationLongitude = selectedLocation.longitude;
    const data = {
      destinationLatitude,
      destinationLongitude,
      defaultBusinessLocationId,
      businessId
    } 
    const sourceLocationResponse = await backend.get(`/marketplace-service/api/v1/business-locations/${defaultBusinessLocationId}`, { headers })
    const sourceLocation = sourceLocationResponse.data;
    const sourceLatitude = sourceLocation.latitude;
    const sourceLongitude = sourceLocation.longitude;
    const response = await backend.post(
      `/orchestrator-service/api/v1/calculators/best-location-delivery-fee`, 
      data, { headers });
    if (response.status === 200) {
      const { deliveryFeeCalculation } = response.data;
      // Add item discounts
      let discountItems = discountList.map(discountItem => {
        return ({
        _id: discountItem._id,
        price: discountItem.discountAmount,
        name: `${discountItem.discountName} Discount`,
        status: 'active',
        currency,
        quantity: 1,
        data: {
          details: "", addOns: []
        }
      })}
      )
      orderItems = [...orderItems, ...discountItems];
      if (!orderItems.length) {
        return undefined;
      }
      //Calculate fees
      const { pricing } = deliveryFeeCalculation.billingBranch;
      const orderExpenses = orderItems.reduce((total, orderItem) => {
        return total + (orderItem.price * orderItem.quantity);
      }, 0);
      const pagesTransactionFee = pricing.pagesTransactionFeePercentageRate * orderExpenses;
      const deliveryFee = deliveryFeeCalculation.deliveryFee;
      const tax = deliveryFee * 15/115
      const orderRevenue = orderExpenses + deliveryFee;
      const target = new Date();
      target.setHours(target.getHours() + 1 + Math.max(...leadTimeHoursList));
      const minutes = target.getMinutes();
      const remainder = minutes % 15;
      if (remainder !== 0) {
        // Round up to the nearest 15 minutes
        target.setMinutes(minutes + (15 - remainder));
      }
      const targetDeliveryTime = target.toISOString()
      const cartOrderFlow: PageOrderFlow = {
        userId: user._id,
        creatorId: user._id,
        sourceId: defaultBusinessLocationId,
        destinationId: selectedLocation._id,
        sourceType: 'businessLocation',
        destinationType: 'userLocation',
        status: 'created',
        distance: deliveryFeeCalculation.drivingDistance,
        deliveryFee,
        deliveryCommission: deliveryFeeCalculation.commission,
        orderExpenses,
        pagesTransactionFee,
        orderRevenue,
        tax,
        currency,
        tip: 0,
        discountAmount: 0,
        valueFee: 0,
        discountDescription: '',
        pickupInstruction: '',
        deliveryInstruction: '',
        channel: 'pages-ui',
        creatingOrder: false,
        acceptedAt: null,
        targetDeliveryTime,
        errors: {},
        pointsEarned: Math.min(Math.floor(deliveryFeeCalculation.drivingDistance), 100),
        deliveryType: deliveryFeeCalculation.deliveryType,
        originBranchCode: deliveryFeeCalculation.originBranch.branchCode,
        branchCode: deliveryFeeCalculation.billingBranch.branchCode,
        calculatingDeliveryFee: false,  
        source: business,
        destination: user,
        data: {
          ratingDetails: '',
          sourceLatitude,
          sourceLongitude,
          destinationLatitude,
          destinationLongitude,
          purchaseMethods: [{name: 'invoice', amount: orderExpenses}],
          paymentMethods: [],
          orderItems     
        }      
      }
      return cartOrderFlow;
      
    }
  }
}