import { useEffect, useState } from "react";
import { Row, Col, Drawer, Image } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  Menu,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { Link } from "react-router-dom";
import { ReduxState } from "../../store/state";
import { User } from "../../store/user/types";
import { checkLoginCookies } from "../../store/auth/initiators";
import { connect } from "react-redux";

interface  HeaderProps {
  isLoggedIn: boolean;
  user: User | null;
  //t: TFunction;
  checkLoginCookies: () => void;
}

interface MenuItemProps {
  isDrawer: boolean;
}

const Header: React.FC<HeaderProps> = ({ isLoggedIn, user, checkLoginCookies }) => {
  
  useEffect(() => {
    checkLoginCookies()
  }, [checkLoginCookies])

  const t = (text: string) => { // temporary until translations are needed
    return text
  }

  const [visible, setVisibility] = useState(false);

  const toggleButton = () => {
    setVisibility(!visible);
  };

  const MenuItem: React.FC<MenuItemProps> = ({ isDrawer }) => {
    const scrollTo = (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      element.scrollIntoView({
        behavior: "smooth",
      });
      setVisibility(false);
    };
    return (
      <>
        <CustomNavLinkSmall>
        <Link to={'/p'} onClick={isDrawer ? toggleButton : undefined}>
            <Span>{t("Pages Marketplace")}</Span>
          </Link>
        </CustomNavLinkSmall>
        {/* <CustomNavLinkSmall>
          <a href={"https://partner.goshowfa.com"}>
            <Span>{t("Partners")}</Span>
          </a>
        </CustomNavLinkSmall> */}
        <CustomNavLinkSmall>
          <Link to={'/jobs'} onClick={isDrawer ? toggleButton : undefined}>
            <Span>{t("Jobs")}</Span>
          </Link>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall>
          { isLoggedIn 
          ? 
          <Link to={'/account'} onClick={isDrawer ? toggleButton : undefined}>
          <Span>{`Hello, ${user?.firstName}!`}</Span>
          </Link>
          :
          <Link to={'/auth/sign-in'} onClick={isDrawer ? toggleButton : undefined}>
          <Span>{t("Sign In")}</Span>
          </Link>
          }
        </CustomNavLinkSmall>
      </>
    );
  };

  return (
    <HeaderSection style={{ marginBottom: 20, borderColor: '#CA0000', borderBottomWidth: 3, boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)"}}>
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <Image
              src="/assets/images/showfa-logo.png"
              alt="Showfa Logo"
              height="30px"
              width="161px"
            /> 
          </LogoContainer>
          <NotHidden>
            <MenuItem isDrawer={false}/>
          </NotHidden>
          <Burger onClick={toggleButton}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={toggleButton}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={toggleButton}>
              <Col span={12}>
                <Menu>Menu</Menu>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem isDrawer={true}/>
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { isLoggedIn } = state.auth;
  const { user } = state.user;
  return { isLoggedIn, user };
};

export default connect(mapStateToProps, { 
  checkLoginCookies,
 })(
  withTranslation()(Header)
);
